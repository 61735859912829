import React, { useState } from "react";

const Footer = () => {

    return (
        <div id="footer">
            <footer class="footer">
                <div class="footer-div">
                    <img src={"/media/logo/LinkRemixLogo.png"} style={{ width: "200px" }} alt={""} />
                    <div class="footer-links">
                        <a href="/page/terms/">Terms</a>&nbsp;&nbsp;&nbsp;
                        <a href="/page/privacy-policy/">Privacy</a>&nbsp;&nbsp;&nbsp;
                        <a href="/about/">About</a>&nbsp;&nbsp;&nbsp;
                        <a href="/page/recruit-writer/">Career</a>&nbsp;&nbsp;&nbsp;
                        <a href="/contact/">Contact</a>&nbsp;&nbsp;&nbsp;
                    </div>
                    <p style={{ width: "100%" }}> &copy; LinkRemix. All rights reserved </p>
                </div>
            </footer>
        </div>
    )
}

export default Footer;