import React, { useState } from "react";
import $ from 'jquery';
import useScript from './utility/useScript';

const Menu = (props) => {
    var user = props.user;
    var authenticated_div = {};
    var notification_div;

    // Open or close user control
    const show_user_control = () => {
        var body = document.body;
        var elm = document.getElementById("user-control");
        var pgt = window.scrollY;

        if(elm.style.display == "none"){
            elm.style.display = "block";
            elm.style.top = pgt + 30 + "px";
            body.style.overflowY = "scroll";
            body.style.overflow = "hidden";
        }
        else{
            elm.style.display = "none";
            body.style.overflow = "auto";
        }
    }

    // Open or close menu for mobile
    const menu_control = () => {
        var menu_elm = document.getElementById("menu");

        if(menu_elm.style.width == "80%"){
            menu_elm.style.width = "0%";
        }
        else{
            menu_elm.style.width = "80%";
        }
    }

    // Open or close the notification tab
    const show_notification = () => {
        var body = document.body;
        var elm = document.getElementById("notification");
        var pgt = window.scrollY;

        // Open notifications
        if(elm.style.display == "none"){
            elm.style.display = "block";
            elm.style.top = pgt + 30 + "px";
            body.style.overflowY = "scroll";
            body.style.overflow = "hidden";

            // load notifications using ajax
            get_notifications();
        }
        // Close
        else{
            elm.style.display = "none";
            body.style.overflow = "auto";
        }
    }

    // Access server using Post request and get notifications
    const get_notifications = () => {
        const data = {
            "command": "get_notifications",
        }
        $.ajax({
            url : "/notification_handler/", // the endpoint
            type : "POST", // http method
            data: data,
            // handle a successful response
            success : function(json) {
                if(json["status"] == "success"){
                    var base_elm = document.getElementById("notification-list");
                    base_elm.innerHTML = "";

                    var notification_on = false;

                    for(var i=0;i<json["notifications"].length;i++){
                        var id = json["notifications"][i]["id"];
                        console.log(id);
                        var message = json["notifications"][i]["message"];
                        var created_time = json["notifications"][i]["created_time"];
                        var read = json["notifications"][i]["read"];

                        var new_div = document.createElement("div");
                        new_div.className = "notified-message";
                        new_div.id = "id-" + id;

                        var HTML = '';
                        if(read == "False"){
                            HTML += '<span class="notification-dot" id="notification-' + id + '"></span>';
                            notification_on = true;
                        }
                        HTML += message;
                        HTML += '<span style="display: block; font-size: 0.9em;">' + created_time + '</span>';
                        new_div.innerHTML = HTML;
                        base_elm.appendChild(new_div);
                        if(read == "False"){
                            $('#id-' + id).attr("onclick", "send_notification_status(" + id + ", true)");
                        }
                    }

                    if(notification_on){
                        $('#notification-dot2').css("display", "block");
                    }
                    else{
                        $('#notification-dot2').css("display", "none");
                        try{
                            $('#notification-dot1').css("display", "none");
                        }
                        catch{}
                    }

                }
            },
            // handle a non-successful response
            error : function(xhr,errmsg,err) {

            }
        });
    }

    // If there is any notification which the user haven't seen, show a red dot beside the notification icon
    if (user.is_authenticated && user.notification_on){
        notification_div = (
            <div>
                 <span class="notification-dot" id="notification-dot1" style={{ right: "auto", top: "10px", left: "14px", width: "7px", height: "7px" }}></span>
            </div>
        )
    }else {
        notification_div = (
            <div>
                 <span class="notification-dot" id="notification-dot2" style={{ display: "none", right: "auto", top: "10px", left: "14px", width: "7px", height: "7px" }}></span>
            </div>
        )
    }

    // Menu: Notification icon and profile picture
    if (user.is_authenticated){
        authenticated_div["menu"] = (
            <div>
                {/*  Notification */}
                <div class="notification-icon" onClick={ show_notification }>
                    <i class="fa fa-bell-o" aria-hidden="true"></i>
                </div>

                {/*  User profile picture */}
                <div class="user-pic pc" id="header-user-pic" style={{ position: "absolute", right: "0" }}>
                    <div onClick={ show_user_control } style={ user.image }></div>
                </div>
                <div class="user-pic float-right mobile" id="header-user-pic" style={{ position: "absolute", right: "0" }}>
                    <div onClick={ show_user_control } style={ user.image }></div>
                </div>
            </div>
        )
    } else{
        authenticated_div["menu"] = (
            <div>
                <button class="login-btn">Login</button>
                <br class="mobile" />
                <button class="signup-btn">Signup</button>
            </div>
        )
    }

    return (
        <>
            <div id="menu-header" class="menu-header vue-variables" style={{ height: "70px" }}>
                <div class="menu-button mobile" onClick={ menu_control }>
                    <i class="fa fa-bars" aria-hidden="true" style={{ fontSize: "1.5em" }}></i>
                </div>
                <div class="menu-search mobile">
                    <i class="fa fa-search"></i>
                </div>

                <div class="header-logo">
                    <a href="/"><img src="/media/logo/LinkRemixLogo.png" /></a>
                </div>

                <div class="pc" style={{ position: "absolute", top: "0px", left: "210px" }}>
                    <a href="/"><div class="menu-item1">Home</div></a>
                    <a href="/edit_post/new/"><div class="menu-item1">Make a post</div></a>
                    <a href="/glossary/"><div class="menu-item1">Glossary</div></a>
                    <a href="/market/"><div class="menu-item1">Market</div></a>
                    <a href="/support/"><div class="menu-item1">Help</div></a>
                    <a href="/about/"><div class="menu-item1" style={{ width: "120px" }}>About</div></a>
                    <a href="/search/"><div class="menu-item1" style={{ width: "50px" }}><i class="fa fa-search"></i></div></a>
                </div>

                <div class="header-menu" style={{ width: "80px" }}>
                    {/* Notification dot */}
                    { notification_div }

                    { authenticated_div["menu"] }

                </div>

                <div class="clear-both"></div>

                <div class="mobile-menu" id="menu" style={{ display: "block" }}>
                    <div class="mobile">
                        <div class="mobile-menu-item" style={{ position: "sticky", top: "0", backgroundColor: "white", zIndex: "60"}}>
                            <div style={{ color: "black" }}>
                                <span style={{ cursor: "pointer", fontSize: "2em", position: "absolute", left: "12px", top: "22px" }} id="menu-close" onClick={ menu_control }>&times;</span>
                            </div>
                            <a href="/" style={{ padding: "20px 0 0 0" }}><img src="/media/logo/LinkRemixLogo.png" style={{ width: "130px" }} /></a>
                        </div>
                        <div>
                            <div class="mobile-menu-item hover10"><a>Home</a></div>
                            <div class="mobile-menu-item hover10"><a>Make a post</a></div>
                            <div class="mobile-menu-item hover10"><a>Glossary</a></div>
                            <div class="mobile-menu-item hover10"><a>Market</a></div>
                            <div class="mobile-menu-item hover10"><a>Help</a></div>
                            <div class="mobile-menu-item hover10"><a>About</a></div>

                            <div class="footer" style={{ position: "sticky", bottom: "0", backgroundColor: "white", zIndex: "60" }}>
                                <div class="footer-div" style={{ whiteSpace: "nowrap" }}>
                                    <div class="footer-links">
                                        <a>Terms</a>&nbsp;&nbsp;&nbsp;
                                        <a>Privacy</a>&nbsp;&nbsp;
                                    </div>
                                    <div class="footer-links">
                                        <a>Career</a>&nbsp;&nbsp;&nbsp;
                                        <a>Contact</a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <p style={{ width: "100%" }}> &copy; LinkRemix. All rights reserved </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr style={{ margin: "0px" }} />
         </>
    )
}

const UserControl = (props) => {
    var user = props.user;
    return (
        <>
            <div class="user-control " id="user-control" style={{display: "none"}}>
                <div class="user-control-opt">
                    <a style={{color: "black"}}>My page</a>
                    <a style={{color: "black", fontWeight: "bold"}}>{ user.user_name }</a>
                    <a style={{color: "black"}}>{ user.email }</a>
                </div>
                <hr />
                <div class="user-control-opt">
                    <a><i class="fa fa-sticky-note-o" aria-hidden="true"></i>&nbsp;Post</a>
                </div>
                <div class="user-control-opt" style={{display: "none"}}>
                    <a>
                        <i class="fa fa-money" aria-hidden="true"></i>&nbsp;Make money
                        <span class="HighlightLabel info" style={{marginLeft: "8px"}}>Soon</span>
                    </a>
                </div>
                <div class="user-control-opt">
                    <a><i class="fa fa-picture-o" aria-hidden="true"></i>&nbsp;Media</a>
                </div>
                <div class="user-control-opt">
                    <a><i class="fa fa-cog" aria-hidden="true"></i>&nbsp;Account</a>
                </div>
                <div class="user-control-opt">
                    <a><i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Logout</a>
                </div>
            </div>
        </>
    )
}


const Notification = (props) => {
     var user = props.user;
    return (
        <>
            <div class="notification" id="notification" style={{display: "none"}}>
                <div class="notification-header">
                    Notification
                </div>
                <div class="notification-list" id="notification-list"></div>
            </div>
        </>
    )
}

const MenuHeader = (props) => {
    var user = props.user;
    var functions = props.functions;
    return (
        <>
            <Menu user={user} />
            <UserControl user={user} />
            <Notification user={user} />
        </>
    )
}

export default MenuHeader;