import React, { useState } from "react";
import useScript from './utility/useScript';


const IsLiked = (props) => {
    var i = props.count;
    var user = props.user;
    var articles = props.articles;

    return (
        <div>
            <div class="float-right" style={{ display: "none", padding: "10px 20px 10px 10px"}}>
                <i class="fa fa-thumbs-o-up fa-lg" aria-hidden="true"></i>
                <span>{ articles[i].likes }</span>
            </div>
            <div class="float-right" style={{ display: "block", padding: "10px 20px 10px 10px"}}>
                <i class="fa fa-thumbs-up fa-lg" aria-hidden="true" style={{ color: "#00b5ff"}}></i>
                <span>{ articles[i].likes }</span>
            </div>
        </div>
    )
}

const IsNotLiked = (props) => {
    var i = props.count;
    var user = props.user;
    var articles = props.articles;

    return (
        <div>
            <div class="float-right" style={{ display: "block", padding: "10px 20px 10px 10px"}}>
                <i class="fa fa-thumbs-o-up fa-lg" aria-hidden="true"></i>
                <span>{ articles[i].likes }</span>
            </div>
            <div class="float-right" style={{ display: "none", padding: "10px 20px 10px 10px"}}>
                <i class="fa fa-thumbs-up fa-lg" aria-hidden="true" style={{ color: "#00b5ff"}}></i>
                <span>{ articles[i].likes }</span>
            </div>
        </div>
    )
}


const Pagers = (props) => {
    var pagers = props.pagers;
    var page = props.page;
    var div_pagers = [];
    for (var i=0;i<pagers.length;i++){
        if(pagers[i] == page){
            div_pagers.push(
                <li>
                    <a class="current-page">
                        <span>{ pagers[i] }</span>
                    </a>
                </li>
            )
        }
        else if(pagers[i] == 0){
            div_pagers.push(
                <li>
                    <a class="dots">
                        <span>&hellip;</span>
                    </a>
                </li>
            )
        }
        else{
            div_pagers.push(
                <li>
                    <a>
                        <span>{ pagers[i] }</span>
                    </a>
                </li>
            )
        }
    }

    return (
        <center>
            <ul class="pager">
                { div_pagers }
            </ul>
        </center>
    )
}

const LatestArticles = (props) => {
    var user = props.user;
    var articles = props.articles;
    var article_rows = [];
    for(var i=0;i<articles.length;i++){

        var div_like;
        if (articles[i].is_liked){
            div_like = <IsLiked count={i} user={user} articles={articles} />
        }else {
            div_like = <IsNotLiked count={i} user={user} articles={articles} />
        }

        article_rows.push(
            <div class="article-card-left" style={{ position: "relative"}}>
                <div class="article-image-l" style={{ backgroundImage: "url('')", backgroundSize: "cover"}} onclick="window.location.href = ''"></div>
                <div>
                    <div class="article-title-l" onclick="window.location.href = ''">{ articles[i].title }</div>
                    <div class="article-content-l heading-size-normal" onclick="window.location.href = ''">{ articles[i].content }</div>
                    <div style={{ position: "absolute", bottom: "0px", width: "100%"}}>
                        <div class="user-pic float-left" style={{ width: "40px", height: "40px", margin: "0 10px 10px 10px"}} onclick="window.location.href = ''">
                            <div style={{ backgroundImage: "url('/media/user_images/linkremix/icon4.png')"}}></div>
                        </div>

                        <div class="float-left" onclick="window.location.href = ''">
                            <div class="article-user-l">&nbsp;{ user.user_name }</div>
                            <div class="article-date-l"><i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;{ articles[i].date }</div>
                        </div>

                        {/* <!-- Like Operation --> */}
                        { div_like }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div class="content-area">
            <div class="left-contents">
                <div class="widget" style={{ marginBottom: "50px"}}>
                    <h5 style={{ marginBottom: "10px"}}><a href="/articles/">Latest Articles</a></h5>
                    { article_rows }

                    <div class="clear-both"></div>

                    {/*<!-- Pagers --> */}
                    <Pagers pagers={[1, 2, 3]} page={1} />
                </div>
            </div>

            <div class="sidebar">
                <div class="sidebar-widget" style={{ marginTop: "10px"}}>

                </div>

                <div class="sidebar-widget" style={{ display: "none"}}>

                </div>

                <div class="sidebar-widget">
                    <h5><a href="/market/">Market</a></h5>
                </div>

                <div class="sidebar-widget">
                    <a class="twitter-timeline" data-tweet-limit="5" href="https://twitter.com/LinkRemix2021?ref_src=twsrc%5Etfw">Tweets by LinkRemix</a>
                    {useScript('https://platform.twitter.com/widgets.js')}
                </div>

                <div class="sidebar-widget">
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    )
}

export default LatestArticles;