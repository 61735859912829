import React, { useState } from "react";

const IndexTop = (props) => {
    var user = props.user;
    var authenticated_div;

    if (!user.is_authenticated){
        authenticated_div = (
            <div>
                <div class="pc">
                    <button class="login-btn" onclick="window.location.href='/login/'">Login</button>
                    <button class="signup-btn" onclick="window.location.href='/signup/'">Signup</button>
                </div>
                <div class="mobile">
                    <button class="login-btn" style={{ width: "300px", height: "40px", marginLeft: "0"}} onclick="window.location.href='/login/'">ログイン</button>
                    <button class="signup-btn" style={{ width: "300px", height: "40px", marginLeft: "0"}} onclick="window.location.href='/signup/'">会員登録</button>
                </div>
            </div>
        )
    }

    return (
        <div class="index-top">
            <div class="index-top-image" style={{ backgroundImage: "url('/media/crypto-trading.jpg')"}}>
                <h1>Where people share their knowledge and experiences</h1>
                <ul>
                    <li><img src="/media/index1.png" alt=""/></li>
                    <li><img src="/media/index2.png" alt=""/></li>
                </ul>

                { authenticated_div }
            </div>
        </div>
    )
}

export default IndexTop;