import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import MenuHeader from './MenuHeader';
import IndexTop from './IndexTop';
import LatestArticles from './LatestArticles';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';

const user = {
    is_authenticated: true,
    notification_on: false,
    user_name: "LinkRemix Official",
    image: { backgroundImage: "url('/media/user_images/linkremix/icon4.png')" },
    user_identification: "/user/linkremix/",
    email: "support@linkremix.com",
}

const articles = [
    {
        article_id: 0,
        title: "Hello World",
        content: "The first article",
        date: "2021/8/1",
        is_liked: false,
        likes: 3,
    },
    {
        article_id: 1,
        title: "The second article",
        content: "The second article",
        date: "2021/8/2",
        is_liked: true,
        likes: 10,
    }
];


ReactDOM.render(
  <React.StrictMode>
    <MenuHeader user={user} />
    <IndexTop user={user} />
    <LatestArticles user={user} articles={articles} />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
